@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');

/* ========== Layout ========== */
.ExperienceTitleGrid {
  padding: 2% 4%;
}

.ExperienceContentGrid {
  padding: 3% 6%;
  padding-top: 0%;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  font-size: 18px;
  color: #444;
  line-height: 1.6;
}

/* ========== Headers ========== */
.ExperienceHeader {
  font-family: "Courier Prime", monospace;
  /* font-weight: 700; */
  font-size: 80px;
  text-align: center;
  color: #1d1d1f;
  margin-bottom: 24px;
}

.TimeLineHeader {
  font-family: "Noto Serif", serif;
  font-weight: 500;
  font-size: 22px;
  padding-left: 16px;
  text-align: left;
  color: #333;
}

/* ========== Responsive ========== */

/* Mobile */
@media only screen and (max-width: 767px) {
  .ExperienceHeader {
    font-size: 36px;
    margin-top: 24px;
  }

  .TimeLineHeader {
    font-size: 18px;
    padding-left: 12px;
  }

  .ExperienceContentGrid {
    font-size: 16px;
    padding: 4%;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .ExperienceHeader {
    font-size: 48px;
  }

  .TimeLineHeader {
    font-size: 20px;
  }

  .ExperienceContentGrid {
    font-size: 17px;
  }
}
