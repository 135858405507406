/* Layout Containers */
.SkillsTitleGrid {
  padding: 2% 4%;
}

.SkillsContentGrid {
  padding: 3% 6%;
  margin-bottom: 48px;
}

.SkillsStatsContainer {
  padding: 2%;
  text-align: center;
}

/* Section Headers */
.SkillsHeader {
  font-family: "Courier Prime", monospace;
  /* font-weight: 700; */
  font-size: 80px;
  text-align: center;
  color: #1d1d1f;
  margin-bottom: 24px;
}

.SkillsSemiHeader {
  font-family: "Noto Serif", serif;
  font-size: 28px;
  color: #333;
  margin: 24px 0 12px;
}

/* Body Text */
.SkillsBody {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  color: #444;
  line-height: 1.7;
}

/* Skill List Items */
.listItem {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  margin-bottom: 8px;
}

.listKey {
  font-weight: 800;
}

/* Icon + Info */
.StatIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 6px solid black;
  border-radius: 12%;
  font-size: 36px;
  font-weight: 900;
  margin: 12%;
}

.StatInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 2em;
}

/* Square Layout */
.square {
  width: 100px;
  height: 100px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
}

/* Parallax Image */
.SkillsParallax {
  background-image: url("../../assets/images/Marven.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  min-height: 300px;
  width: 100%;
}

/* ========== Responsive Tweaks ========== */

/* Mobile */
@media only screen and (max-width: 767px) {
  .SkillsHeader {
    font-size: 36px;
    margin-top: 24px;
  }

  .SkillsSemiHeader {
    font-size: 20px;
    text-align: center;
  }

  .SkillsBody {
    font-size: 16px;
  }

  .SkillsParallax {
    min-height: 200px;
    width: 100%;
    margin-bottom: 16px;
  }

  .listItem {
    font-size: 14px;
  }

  .StatIcon {
    font-size: 24px;
    margin: 8%;
  }

  .StatInfo {
    font-size: 14px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .SkillsHeader {
    font-size: 48px;
  }

  .SkillsSemiHeader {
    font-size: 24px;
  }

  .SkillsBody {
    font-size: 17px;
  }

  .SkillsParallax {
    min-height: 250px;
    width: 100%;
  }
}

/* Large Screens */
@media only screen and (min-width: 1281px) {
  .SkillsParallax {
    display: block;
  }
}
