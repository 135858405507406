.profileBlock {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #1D2B64;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F8CDDA, #1D2B64);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F8CDDA, #1D2B64); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 
}

.sectionBlock{
    min-height: 100vh;
}

.deviderBlock{
  min-height: 10vh;
}


.ProfileHeaderParallax {
    background-color: #faf9f6;
    /* height: 90vh !important; */
    /* background-image: url("./assets/images/profileHeaderBG.jpeg"); */
  
    /* Set a specific height */
    /* min-height: 40vh; */
    color: white;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    filter: blur(2px);
  }


  @media (min-height: 700px) {
    .sectionBlock {
      min-height: 110vh;
    }
  }